import React, { useState } from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"
import { WallpaperDesc } from "../graphql/SmallQueries"

const SingleWallpaperQueryComponent = ({
  // data,
  wallpaper,
  allLuxuryWallpapers,
  generalColorFilteredWallpaper,
}) => {
  // console.log(data)
  const data = wallpaper?.data ?? undefined
  const id = wallpaper?.id ?? undefined
  // console.log(id)

  const productCategoryName = WallpaperDesc()[0].name
  const productType = "wallpaper"
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  // 1 udaa duudaj bn. SAIN BN.
  // console.log(currentLanguage)

  const productName = data?.Name ?? undefined
  // console.log(productName)
  const productSlug = productName
    ? productName.replace(/\s+/g, "-").toLowerCase()
    : "#"

  // ########## TYPE ##############
  const typePath = data?.Type ?? undefined
  const angilal = typePath
    ? typePath.map((item, index) => {
        const typePath = item?.data ?? undefined
        const nameMn = typePath?.nameMn?.trim() ?? undefined
        const nameEn = typePath?.nameEn?.trim() ?? undefined
        const name = currentLanguage === "mn" ? nameMn : nameEn

        const textMn = typePath?.textMn?.trim() ?? undefined
        const textEn = typePath?.textEn?.trim() ?? undefined
        const text = currentLanguage === "mn" ? textMn : textEn

        const imagepath = typePath?.Image ?? undefined
        const sharp = imagepath ? getImage(imagepath.localFiles[0]) : undefined
        const image = sharp ? (
          <GatsbyImage image={sharp} alt={name} className="w-full" />
        ) : undefined

        const iconpath = typePath?.Icon ?? undefined

        const iconSharp = iconpath
          ? getImage(iconpath.localFiles[0])
          : undefined
        const icon = iconSharp ? (
          <GatsbyImage
            image={iconSharp}
            alt={`${name} icon`}
            className="w-10 h-full"
          />
        ) : undefined
        // ########### Related Type Wallpapers ###############
        const relWallPath = typePath?.Wallpaper ?? undefined
        const relatedTypeWallpapersBeforeFilter = relWallPath
          ? relWallPath.map((item, index) => {
              const subwalpath = item?.data ?? undefined
              const name = subwalpath?.Name ?? undefined
              const slug = name ? name.replace(/\s+/g, "-").toLowerCase() : "#"

              const saleBeforeConverted = subwalpath?.Sale ?? undefined
              const sale = saleBeforeConverted
                ? saleBeforeConverted * 100 + "%"
                : undefined

              const newProduct = subwalpath?.New ?? undefined
              const hot = subwalpath?.Hot ?? undefined
              const modern = subwalpath?.Modern ?? undefined
              const best = subwalpath?.Bestseller ?? undefined

              const relwalImagepath = subwalpath?.Image ?? undefined
              const relwalsharp = relwalImagepath
                ? getImage(relwalImagepath.localFiles[0])
                : undefined
              const image = relwalsharp ? (
                <GatsbyImage
                  image={relwalsharp}
                  alt={name}
                  className="w-full"
                />
              ) : undefined

              return {
                name,
                image,
                slug,
                sale,
                newProduct,
                hot,
                modern,
                best,
                productType,
              }
            })
          : undefined

        // console.log(relWallPath)

        // console.log(relatedTypeWallpapersBeforeFilter)
        const relWall = relatedTypeWallpapersBeforeFilter
          .filter(wallpaper => wallpaper.name !== productName)
          .sort(function (a, b) {
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          })

        return { name, text, image, icon, relWall, productType }
      })
    : undefined

  // console.log(angilal.map(wall => wall.relWall))
  // console.log(angilal)

  // ####### ONTSLOG ##############
  const ontslogPath = data?.Ontslog ?? undefined
  const ontslog = ontslogPath
    ? ontslogPath.map((item, index) => {
        const ontslogPath = item?.data ?? undefined
        const nameMn = ontslogPath?.nameMn?.trim() ?? undefined
        const nameEn = ontslogPath?.nameEn?.trim() ?? undefined
        const name = currentLanguage === "mn" ? nameMn : nameEn

        const textMn = ontslogPath?.textMn?.trim() ?? undefined
        const textEn = ontslogPath?.textEn?.trim() ?? undefined
        const text = currentLanguage === "mn" ? textMn : textEn

        // const sharpBefore = ontslogPath?.Image?.locaFiles[0] ?? undefined
        // const sharp = getImage(sharpBefore)
        const imagepath = ontslogPath?.Image ?? undefined

        const sharp = imagepath ? getImage(imagepath.localFiles[0]) : undefined
        const image = sharp ? (
          <GatsbyImage image={sharp} alt={name} className="w-full" />
        ) : undefined

        const iconpath = ontslogPath?.Icon ?? undefined
        const iconSharp = iconpath
          ? getImage(iconpath.localFiles[0])
          : undefined
        const icon = iconSharp ? (
          <GatsbyImage
            image={iconSharp}
            alt={`${name} icon`}
            className="flex w-10 h-full"
          />
        ) : undefined

        // ########### Related Ontslog Wallpapers ###############
        const relWallPath = ontslogPath?.Wallpaper ?? undefined
        const relatedWallpapersBeforeFilter = relWallPath
          ? relWallPath.map((item, index) => {
              const subwalpath = item?.data ?? undefined
              const name = subwalpath?.Name ?? undefined
              const slug = name ? name.replace(/\s+/g, "-").toLowerCase() : "#"

              const saleBeforeConverted = subwalpath?.Sale ?? undefined
              const sale = saleBeforeConverted
                ? saleBeforeConverted * 100 + "%"
                : undefined

              const newProduct = subwalpath?.New ?? undefined
              const hot = subwalpath?.Hot ?? undefined
              const modern = subwalpath?.Modern ?? undefined
              const best = subwalpath?.Bestseller ?? undefined

              const relwalImagepath = subwalpath?.Image ?? undefined
              const relwalsharp = relwalImagepath
                ? getImage(relwalImagepath.localFiles[0])
                : undefined
              const image = relwalsharp ? (
                <GatsbyImage
                  image={relwalsharp}
                  alt={name}
                  className="w-full"
                />
              ) : undefined

              return {
                name,
                image,
                slug,
                sale,
                newProduct,
                hot,
                modern,
                best,
                productType,
              }
            })
          : undefined

        const relWall = relatedWallpapersBeforeFilter.filter(
          wallpaper => wallpaper.name !== productName
        )

        return { name, text, image, icon, relWall, productType }
      })
    : undefined

  // console.log(ontslog)

  // ########## LUXURY ################
  const luxuryPath = data?.Luxury ?? undefined
  const luxury = luxuryPath
    ? luxuryPath.map((item, index) => {
        const luxuryPath = item?.data ?? undefined
        const nameMn = luxuryPath?.nameMn?.trim() ?? undefined
        const nameEn = luxuryPath?.nameEn?.trim() ?? undefined
        const name = currentLanguage === "mn" ? nameMn : nameEn

        const textMn = luxuryPath?.textMn?.trim() ?? undefined
        const textEn = luxuryPath?.textEn?.trim() ?? undefined
        const text = currentLanguage === "mn" ? textMn : textEn

        // const sharpBefore = luxuryPath?.Image?.locaFiles[0] ?? undefined
        // const sharp = getImage(sharpBefore)
        const imagepath = luxuryPath?.Image ?? undefined

        const sharp = imagepath ? getImage(imagepath.localFiles[0]) : undefined
        const image = sharp ? (
          <GatsbyImage image={sharp} alt={name} className="w-full" />
        ) : undefined

        const iconpath = luxuryPath?.Icon ?? undefined
        const iconSharp = iconpath
          ? getImage(iconpath.localFiles[0])
          : undefined
        const icon = iconSharp ? (
          <GatsbyImage
            image={iconSharp}
            alt={`${name} icon`}
            className="w-10 h-full"
          />
        ) : undefined

        // ########### Related Luxury Wallpapers ###############
        const relWallPath = luxuryPath?.Wallpaper ?? undefined
        const relatedWallpapersBeforeFilter = relWallPath
          ? relWallPath.map((item, index) => {
              const subwalpath = item?.data ?? undefined
              const name = subwalpath?.Name ?? undefined

              const slug = name ? name.replace(/\s+/g, "-").toLowerCase() : "#"

              const saleBeforeConverted = subwalpath?.Sale ?? undefined
              const sale = saleBeforeConverted
                ? saleBeforeConverted * 100 + "%"
                : undefined

              const newProduct = subwalpath?.New ?? undefined
              const hot = subwalpath?.Hot ?? undefined
              const modern = subwalpath?.Modern ?? undefined
              const best = subwalpath?.Bestseller ?? undefined

              const relwalImagepath = subwalpath?.Image ?? undefined
              const relwalsharp = relwalImagepath
                ? getImage(relwalImagepath.localFiles[0])
                : undefined
              const image = relwalsharp ? (
                <GatsbyImage
                  image={relwalsharp}
                  alt={name}
                  className="w-full"
                />
              ) : undefined

              return {
                name,
                image,
                slug,
                sale,
                newProduct,
                hot,
                modern,
                best,
                productType,
              }
            })
          : undefined

        const relWall = relatedWallpapersBeforeFilter
          .filter(wallpaper => wallpaper.name !== productName)
          .sort(function (a, b) {
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          })

        // console.log(relWall)

        return { name, text, image, icon, relWall, productType }
      })
    : undefined

  // console.log(luxury)
  const allLuxPath = allLuxuryWallpapers?.nodes ?? undefined
  const allLuxWalls = allLuxPath?.map((item, index) => {
    const wallPath = item?.data?.Wallpaper ?? undefined
    const subWall = wallPath?.map((item, index) => {
      const name = item?.data?.Name ?? undefined
      const slug = name ? name.replace(/\s+/g, "-").toLowerCase() : "#"

      const saleBeforeConverted = item?.data?.Sale ?? undefined
      const sale = saleBeforeConverted
        ? saleBeforeConverted * 100 + "%"
        : undefined

      const newProduct = item?.data?.New ?? undefined
      const hot = item?.data?.Hot ?? undefined
      const modern = item?.data?.Modern ?? undefined
      const best = item?.data?.Bestseller ?? undefined

      const sharpPath = item?.data?.Image ?? undefined
      const sharp = sharpPath ? getImage(sharpPath.localFiles[0]) : undefined
      const image = sharp ? (
        <GatsbyImage image={sharp} alt={name} className="w-full" />
      ) : undefined

      return {
        name,
        image,
        slug,
        sale,
        newProduct,
        hot,
        modern,
        best,
        productType,
      }
    })

    return subWall
  })

  const filteredAllLuxWalls = allLuxWalls.filter(Boolean).flat()

  // console.log(filteredAllLuxWalls)
  function getUnique(array) {
    let uniqueArrayName = []
    let uniqueArray = []

    for (let i = 0; i < array.length; i++) {
      if (uniqueArrayName.indexOf(array[i].name) === -1) {
        uniqueArrayName.push(array[i].name)
        uniqueArray.push(array[i])
      }
    }
    return uniqueArray
  }

  const uniqueLuxWalls = getUnique(filteredAllLuxWalls)
  const filteredLuxWalls = uniqueLuxWalls
    .filter(wallpaper => wallpaper.name !== productName)
    .sort(function (a, b) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })

  // console.log(filteredLuxWalls)

  // console.log(getUnique(filteredAllLuxWalls))

  // ########## PATTERN ################
  const patternPath = data?.Pattern ?? undefined
  const pattern = patternPath
    ? patternPath.map((item, index) => {
        const patternPath = item?.data ?? undefined
        const nameMn = patternPath?.nameMn?.trim() ?? undefined
        const nameEn = patternPath?.nameEn?.trim() ?? undefined
        const name = currentLanguage === "mn" ? nameMn : nameEn

        const textMn = patternPath?.textMn?.trim() ?? undefined
        const textEn = patternPath?.textEn?.trim() ?? undefined
        const text = currentLanguage === "mn" ? textMn : textEn

        // const sharpBefore = patternPath?.Image?.locaFiles[0] ?? undefined
        // const sharp = getImage(sharpBefore)
        const imagepath = patternPath?.Image ?? undefined
        const sharp = imagepath ? getImage(imagepath.localFiles[0]) : undefined
        const image = sharp ? (
          <GatsbyImage image={sharp} alt={name} className="w-full" />
        ) : undefined

        const iconpath = patternPath?.Icon ?? undefined
        const iconSharp = iconpath
          ? getImage(iconpath.localFiles[0])
          : undefined
        const icon = iconSharp ? (
          <GatsbyImage
            image={iconSharp}
            alt={`${name} icon`}
            className="w-10 h-full"
          />
        ) : undefined

        // ########### Related Pattern Wallpapers ###############
        const relWallPath = patternPath?.Wallpaper ?? undefined
        const relatedWallpapersBeforeFilter = relWallPath
          ? relWallPath.map((item, index) => {
              const subwalpath = item?.data ?? undefined
              const name = subwalpath?.Name ?? undefined
              const slug = name ? name.replace(/\s+/g, "-").toLowerCase() : "#"

              const saleBeforeConverted = subwalpath?.Sale ?? undefined
              const sale = saleBeforeConverted
                ? saleBeforeConverted * 100 + "%"
                : undefined

              const newProduct = subwalpath?.New ?? undefined
              const hot = subwalpath?.Hot ?? undefined
              const modern = subwalpath?.Modern ?? undefined
              const best = subwalpath?.Bestseller ?? undefined

              const relwalImagepath = subwalpath?.Image ?? undefined
              const relwalsharp = relwalImagepath
                ? getImage(relwalImagepath.localFiles[0])
                : undefined
              const image = relwalsharp ? (
                <GatsbyImage
                  image={relwalsharp}
                  alt={name}
                  className="w-full"
                />
              ) : undefined

              return {
                name,
                image,
                slug,
                sale,
                newProduct,
                hot,
                modern,
                best,
                productType,
              }
            })
          : undefined

        const relWall = relatedWallpapersBeforeFilter.filter(
          wallpaper => wallpaper.name !== productName
        )

        // console.log(relWall)

        return { name, text, image, icon, relWall, productType }
      })
    : undefined

  const patternNames = pattern
    ? pattern.map((item, index) => {
        const words = item.name.split(" ")
        const firstWord = words[0].toLowerCase()
        return firstWord
      })
    : undefined

  const joinedPatternNames = patternNames?.join(", ").toString()

  const capitalizedPatternNames =
    joinedPatternNames?.charAt(0).toUpperCase() + joinedPatternNames?.slice(1)
  // console.log(capitalizedPatternNames)

  const joinedPatternRelWalls = pattern
    ? pattern.map((item, index) => {
        const relWalls = item.relWall
        return relWalls
      })
    : undefined

  const flatternJoinPatWalls = joinedPatternRelWalls
    ? joinedPatternRelWalls?.flat()
    : undefined

  const finishPatWallsJoin = flatternJoinPatWalls
    ? flatternJoinPatWalls
        .filter(wallpaper => wallpaper.name !== productName)
        .sort(function (a, b) {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
    : undefined

  // console.log(joinedRelWalls)

  // ########## ROOM ################
  const roomPath = data?.Room ?? undefined
  const room = roomPath
    ? roomPath.map((item, index) => {
        const roomPath = item?.data ?? undefined
        const nameMn = roomPath?.nameMn?.trim() ?? undefined
        const nameEn = roomPath?.nameEn?.trim() ?? undefined
        const name = currentLanguage === "mn" ? nameMn : nameEn

        const textMn = roomPath?.textMn?.trim() ?? undefined
        const textEn = roomPath?.textEn?.trim() ?? undefined
        const text = currentLanguage === "mn" ? textMn : textEn

        // const sharpBefore = roomPath?.Image?.locaFiles[0] ?? undefined
        // const sharp = getImage(sharpBefore)
        const imagepath = roomPath?.Image ?? undefined

        const sharp = imagepath ? getImage(imagepath.localFiles[0]) : undefined
        const image = sharp ? (
          <GatsbyImage image={sharp} alt={name} className="w-full" />
        ) : undefined

        const iconpath = roomPath?.Icon ?? undefined
        const iconSharp = iconpath
          ? getImage(iconpath.localFiles[0])
          : undefined
        const icon = iconSharp ? (
          <GatsbyImage
            image={iconSharp}
            alt={`${name} icon`}
            className="w-10 h-full"
          />
        ) : undefined

        // ########### Related Room Wallpapers ###############
        const relWallPath = roomPath?.Wallpaper ?? undefined
        const relatedWallpapersBeforeFilter = relWallPath
          ? relWallPath.map((item, index) => {
              const subwalpath = item?.data ?? undefined
              const name = subwalpath?.Name ?? undefined

              const slug = name ? name.replace(/\s+/g, "-").toLowerCase() : "#"

              const saleBeforeConverted = subwalpath?.Sale ?? undefined
              const sale = saleBeforeConverted
                ? saleBeforeConverted * 100 + "%"
                : undefined

              const newProduct = subwalpath?.New ?? undefined
              const hot = subwalpath?.Hot ?? undefined
              const modern = subwalpath?.Modern ?? undefined
              const best = subwalpath?.Bestseller ?? undefined

              const relwalImagepath = subwalpath?.Image ?? undefined
              const relwalsharp = relwalImagepath
                ? getImage(relwalImagepath.localFiles[0])
                : undefined
              const image = relwalsharp ? (
                <GatsbyImage
                  image={relwalsharp}
                  alt={name}
                  className="w-full"
                />
              ) : undefined

              return {
                name,
                image,
                slug,
                sale,
                newProduct,
                hot,
                modern,
                best,
                productType,
              }
            })
          : undefined

        const relWall = relatedWallpapersBeforeFilter.filter(
          wallpaper => wallpaper.name !== productName
        )

        // console.log(relWall)

        return { name, text, image, icon, relWall, productType }
      })
    : undefined

  // ######## TAILBAR ##########
  const tailbarEnBefore =
    data?.tailbarEn?.internal?.content?.trim() ?? undefined
  const tailbarMnBefore =
    data?.tailbarMn?.internal?.content?.trim() ?? undefined

  const tailbarEn =
    tailbarEnBefore !== undefined && tailbarEnBefore !== ""
      ? tailbarEnBefore
      : undefined
  const tailbarMn =
    tailbarMnBefore !== undefined && tailbarMnBefore !== ""
      ? tailbarMnBefore
      : undefined
  const tailbar = currentLanguage === "mn" ? tailbarMn : tailbarEn

  // ######### Other shinjuud ########
  const link = data?.Link?.trim() ?? undefined
  const hot = data?.Hot ?? false
  const newProduct = data?.New ?? false
  const best = data?.Bestseller ?? false
  const modern = data?.Modern ?? false
  const ontslohBaraa = data?.Ontsloh_baraa_bolgoh ?? false

  // ############ SALE ############
  const saleBeforeConverted = data?.Sale ?? undefined
  const sale = saleBeforeConverted ? saleBeforeConverted * 100 + "%" : undefined

  // ############## MAINIMAGE ############
  const mainImagePath = data?.mainImage ?? undefined
  const sharpMain = mainImagePath
    ? getImage(data.mainImage.localFiles[0])
    : undefined
  const image = sharpMain ? (
    <GatsbyImage image={sharpMain} alt={productName} className="w-full" />
  ) : undefined

  // ############ TUSLAH IMAGES ###########
  const tuslahImagePath = data?.tuslahImages ?? undefined

  const pathTuslahImages = tuslahImagePath?.localFiles ?? undefined
  const tuslahImages = pathTuslahImages
    ? pathTuslahImages.map((item, index) => {
        const sharpTuslahImage = getImage(item)
        const tuslahImage = sharpTuslahImage ? (
          <GatsbyImage
            image={sharpTuslahImage}
            alt={`${productName}-${index + 1}`}
            className="w-full"
          />
        ) : undefined
        return tuslahImage
      })
    : undefined

  // ############ COLOR ###############
  const colorPath = data?.Color ?? undefined
  const color = colorPath
    ? colorPath.map((item, index) => {
        const colorSubPath = item?.data ?? undefined
        const colorNameMn = colorSubPath
          ? colorSubPath?.mn?.trim() ?? undefined
          : undefined
        const colorNameEn = colorSubPath
          ? colorSubPath?.en?.trim() ?? undefined
          : undefined
        const colorName = currentLanguage === "mn" ? colorNameMn : colorNameEn

        const colorHex = colorSubPath?.Hex_Color?.trim() ?? undefined
        const hex = colorHex.charAt(0) === "#" ? colorHex : "#" + colorHex

        const generalColor = colorSubPath?.General_color?.trim() ?? undefined

        return { colorName, hex, generalColor, productType }
      })
    : undefined

  // console.log(color)

  // ########### RelatedColorWallpapers #########
  const relColorPath = generalColorFilteredWallpaper.nodes ?? undefined
  const relColorWalls = relColorPath
    ? relColorPath.map((item, index) => {
        const subPath = item?.data ?? undefined
        const name = subPath?.Name ?? undefined
        const slug = name ? name.replace(/\s+/g, "-").toLowerCase() : "#"

        const saleBeforeConverted = subPath?.Sale ?? undefined
        const sale = saleBeforeConverted
          ? saleBeforeConverted * 100 + "%"
          : undefined

        const newProduct = subPath?.New ?? undefined
        const hot = subPath?.Hot ?? undefined
        const modern = subPath?.Modern ?? undefined
        const best = subPath?.Bestseller ?? undefined

        const sharp = subPath?.Image
          ? getImage(subPath.Image.localFiles[0])
          : undefined
        const image = sharp ? (
          <GatsbyImage image={sharp} alt={name} className="w-full" />
        ) : undefined

        return {
          name,
          image,
          slug,
          sale,
          newProduct,
          hot,
          modern,
          best,
          productType,
        }
      })
    : undefined

  const filteredRelColorWalls = relColorWalls
    .filter(wallpaper => wallpaper.name !== productName)
    .sort(function (a, b) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
  // console.log(filteredRelColorWalls)

  // ############## COUNRTY ############
  const countryPath = data?.Country ?? undefined
  const countrySubPath = countryPath ? countryPath[0]?.data : undefined
  const countryNameMn = countrySubPath ? countrySubPath.mn : undefined
  const countryNameEn = countrySubPath ? countrySubPath.en : undefined
  const country = currentLanguage === "mn" ? countryNameMn : countryNameEn

  const flagpath = countrySubPath?.Flag ?? undefined
  const countryFlagSharp = flagpath
    ? getImage(countrySubPath.Flag.localFiles[0])
    : undefined
  const flag = countryFlagSharp ? (
    <GatsbyImage image={countryFlagSharp} alt={country} className="" />
  ) : undefined

  // ########### BRAND ##############
  const brandPath = data?.Brand ?? undefined
  const brandSubPath = brandPath ? brandPath[0]?.data : undefined
  const brandNameMn = brandSubPath?.mn?.trim() ?? undefined
  const brandNameEn = brandSubPath?.en?.trim() ?? undefined
  const brand = currentLanguage === "mn" ? brandNameMn : brandNameEn

  const logopath = brandSubPath?.Logo ?? undefined

  const brandSharp = logopath
    ? getImage(brandSubPath.Logo.localFiles[0])
    : undefined
  const brandLogo = brandSharp ? (
    <GatsbyImage image={brandSharp} alt={brand} className="w-full" />
  ) : undefined

  // ########### SIZE ############
  const sizePath = data?.Size ?? undefined
  const sizeSubPath = sizePath ? sizePath[0].data : undefined

  const urgunpath = sizeSubPath?.urgun ?? undefined
  const urgunWithoutUnit = urgunpath ?? undefined
  const urgun = urgunWithoutUnit
    ? `${urgunWithoutUnit.toString()}${currentLanguage === "mn" ? "см" : "cm"}`
    : undefined

  const urtpath = sizeSubPath?.urt ?? undefined
  const urtMn = urtpath ? urtpath + "м" : undefined
  const urtEn = urtpath ? urtpath + "m" : undefined
  const urt = currentLanguage === "mn" ? urtMn : urtEn

  const talbaipath = sizeSubPath?.talbai ?? undefined
  const talbaiMn = talbaipath ? talbaipath + "м" : undefined
  const talbaiEn = talbaipath ? talbaipath + "m" : undefined
  const talbai = currentLanguage === "mn" ? talbaiMn : talbaiEn

  // ######### Swiper #############
  const allSlideImage = [image].concat(tuslahImages)
  // const [zoom, setZoom] = useState(false)
  // const [zoomImage, setZoomImage] = useState(null)

  // console.log(angilal)

  // ################ BADGE SECTION ####################
  const badges = (
    <div className="absolute z-10 flex flex-col items-end space-y-1 select-none top-2 right-4">
      {best && (
        <div className="">
          <span className="px-2 py-1 text-xs font-bold text-red-500 uppercase bg-white rounded-full shadow-md bg-opacity-90">
            Bestseller
          </span>
        </div>
      )}
      {newProduct && (
        <div className="flex ">
          <span className="px-2 py-1 text-xs font-bold uppercase bg-white rounded-full shadow-md bg-opacity-90 text-brand">
            New
          </span>
        </div>
      )}
      {hot && (
        <div className="">
          <span className="px-2 py-1 text-xs font-bold text-gray-200 uppercase rounded-full shadow-md bg-opacity-90 bg-brand">
            Hot
          </span>
        </div>
      )}
      {modern && (
        <div className="">
          <span className="px-2 py-1 text-xs font-bold text-gray-200 uppercase rounded-full shadow-md bg-opacity-90 bg-brand">
            Modern
          </span>
        </div>
      )}
      {sale && (
        <div className="">
          <span className="px-2 py-1 text-xs font-bold uppercase rounded-full shadow-md bg-opacity-90">
            {sale}
          </span>
        </div>
      )}
    </div>
  )
  // console.log(badges)

  return {
    id,
    productCategoryName,
    productType,
    productName,
    productSlug,
    angilal,
    ontslog,
    luxury,
    filteredLuxWalls,
    pattern,
    capitalizedPatternNames,
    finishPatWallsJoin,
    room,
    tailbar,
    link,
    hot,
    newProduct,
    best,
    modern,
    ontslohBaraa,
    sale,
    image,
    tuslahImages,
    color,
    filteredRelColorWalls,
    country,
    flag,
    brand,
    brandLogo,
    urgun,
    urt,
    talbai,
    allSlideImage,
    badges,
  }
}

export default SingleWallpaperQueryComponent
