import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import PageHeader from "./PageHeader"
import Breadcrumbs from "../components/Breadcrumbs"
import SingleWallpaperQueryComponent from "../components/SingleWallpaperQueryComponent"
import SingleProductIntroduction from "../components/SingleProductIntroduction"
import PageTitle from "../components/PageTitle"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"
import { useTranslation } from "react-i18next"
import SEO from '../components/seo'

const SingleProductTemplate = ({
  data: {
    wallpaper,
    // wallpaper: { data: beforeUseState },
    generalColorFilteredWallpaper,
    allLuxuryWallpapers,
    prevWall,
    nextWall,
  },
  pageContext,
}) => {
  const prev = prevWall?.data ?? undefined
  const next = nextWall?.data ?? undefined

  const prevSlug = prev?.Name?.replace(/\s+/g, "-").toLowerCase()
  const nextSlug = next?.Name?.replace(/\s+/g, "-").toLowerCase()
  // const prevSharp = prev?.Image ? getImage(prev.Image.localFiles[0]) : undefined
  // const prevImage = prevSharp ? (
  //   <GatsbyImage image={prevSharp} alt={prev.Name} className="w-full" />
  // ) : undefined
  // const nextSharp = next?.Image ? getImage(next.Image.localFiles[0]) : undefined
  // const nextImage = nextSharp ? (
  //   <GatsbyImage image={nextSharp} alt={next.Name} className="w-full" />
  // ) : undefined

  const wall = SingleWallpaperQueryComponent({
    // data,
    wallpaper,
    generalColorFilteredWallpaper,
    allLuxuryWallpapers,
  })
  // console.log(wall)

  // const { i18n } = useTranslation()
  // const currentLanguage = i18n.language

  // console.log(data)

  // const [loading, setLoading] = useState(false)
  // const [pageShow, setPageShow] = useState()
  // const [pageContent, setPageContent] = useState()
  // const [beforeLang, setBeforeLang] = useState(currentLanguage)

  // // TODO
  // useEffect(() => {
  //   setLoading(true)
  //   // console.log(loading)
  //   if (loading) {
  //     let content = <SingleProductIntroduction data={wall} />
  //     setPageContent(content)
  //   }
  //   setLoading(false)
  // }, [loading, wall ])

  // useEffect(() => {
  //   let content = <SingleProductIntroduction data={wall} />
  //   setPageContent(content)
  // }, [currentLanguage])

  return (
    <Layout>
      <SEO title={wall.productName} />
      {/* Next Prev button section */}
      {/* <div className="relative inset-0 items-center justify-between mx-10 mb-20 space-y-4 text-gray-500 sm:flex md:text-gray-100 md:fixed md:mb-0 sm:space-y-0 ">
        <Link
          to={`/wallpaper/${prevSlug && prevSlug}`}
          className={`flex items-center p-4 transition bg-white shadow md:rounded-full bg-opacity-5 group hover:bg-opacity-10 relative z-50 backdrop-filter backdrop-blur-sm ${
            prev ? "" : "pointer-events-none opacity-40"
          }`}
          onClick={() => setLoading(true)}
        >
          <BsChevronLeft className="w-10 h-10 text-gray-300 md:h-16 md:w-16 group-hover:text-gray-400" />
          <span className="md:hidden">Өмнөх бүтээгдэхүүн</span>
        </Link>
        <div>
          <Link
            to={`/wallpaper/${nextSlug && nextSlug}`}
            className={`flex items-center p-4 transition bg-white md:rounded-full bg-opacity-5 group hover:bg-opacity-10 justify-end shadow backdrop-filter backdrop-blur-sm ${
              next ? "" : "pointer-events-none opacity-40"
            }`}
            onClick={() => setLoading(true)}
          >
            <span className="text-right md:hidden">Дараагийн бүтээгдэхүүн</span>
            <BsChevronRight className="w-10 h-10 text-gray-300 md:h-16 md:w-16 group-hover:text-gray-400" />
          </Link>
        </div>
      </div> */}

      {/* <PageHeader title={wall.productName} /> */}
      <PageTitle title={wall.productName} />

      <div className="relative z-10 max-w-screen-lg mx-auto my-6 md:shadow-xl md:p-10 md:bg-white md:my-10">
        {/* ############## BREADCRUMP ################ */}
        <Breadcrumbs
          productType={wall.productCategoryName}
          productName={wall.productName}
          nameEn="wallpaper"
        />

        {/* {loading ? <h1>Loading...</h1> : pageContent} */}
        {/* {!loading && pageContent} */}
        <SingleProductIntroduction data={wall} />
      </div>

      {/* <div className="flex justify-between max-w-screen-lg mx-auto">
        {prev && (
          <div>
            <Link to={`/wallpaper/${prevSlug && prevSlug}`}>{prev.Name}</Link>
          </div>
        )}
        {next && (
          <div>
            <Link to={`/wallpaper/${nextSlug && nextSlug}`}>{next.Name}</Link>
          </div>
        )}
      </div> */}
    </Layout>
  )
}

export const query = graphql`
  query GetSinlgeWallpaper(
    $id: String!
    $generalColor: String
    $prevId: String
    $nextId: String
  ) {
    prevWall: airtable(id: { eq: $prevId }) {
      data {
        Name
        Image {
          localFiles {
            childImageSharp {
              gatsbyImageData(
                width: 400
                placeholder: NONE
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
    nextWall: airtable(id: { eq: $nextId }) {
      data {
        Name
        Image {
          localFiles {
            childImageSharp {
              gatsbyImageData(
                width: 400
                placeholder: NONE
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
    generalColorFilteredWallpaper: allAirtable(
      filter: {
        table: { eq: "Wallpaper" }
        data: {
          Color: {
            elemMatch: { data: { General_color: { eq: $generalColor } } }
          }
        }
      }
    ) {
      nodes {
        data {
          Name
          Hot
          New
          Bestseller
          Sale
          Modern
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 400)
              }
            }
          }
        }
      }
      totalCount
    }
    allLuxuryWallpapers: allAirtable(filter: { table: { eq: "Luxury" } }) {
      nodes {
        data {
          Wallpaper {
            data {
              Name
              Hot
              New
              Bestseller
              Sale
              Modern
              Image {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 400)
                  }
                }
              }
            }
          }
        }
      }
    }
    wallpaper: airtable(id: { eq: $id }) {
      data {
        Name
        Type {
          data {
            nameMn: mn
            nameEn: en
            textMn: Mongol_tailbar
            textEn: English_tailbar
            Image {
              localFiles {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 800, quality: 100)
                }
              }
            }
            Icon {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    width: 100
                    placeholder: NONE
                    layout: CONSTRAINED
                  )
                }
              }
            }
            Wallpaper {
              data {
                Name
                Hot
                New
                Bestseller
                Sale
                Modern
                Image {
                  localFiles {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED, width: 400)
                    }
                  }
                }
              }
            }
          }
        }
        Ontslog {
          data {
            nameMn: mn
            nameEn: en
            textMn: Mongol_tailbar
            textEn: English_tailbar
            Image {
              localFiles {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 800, quality: 100)
                }
              }
            }
            Icon {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    width: 100
                    placeholder: NONE
                    layout: CONSTRAINED
                  )
                }
              }
            }
            Wallpaper {
              data {
                Name
                Hot
                New
                Bestseller
                Sale
                Modern
                Image {
                  localFiles {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED, width: 400)
                    }
                  }
                }
              }
            }
          }
        }
        Luxury {
          data {
            nameMn: mn
            nameEn: en
            textMn: Mongol_tailbar
            textEn: English_tailbar
            Image {
              localFiles {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 800, quality: 100)
                }
              }
            }
            Icon {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    width: 100
                    placeholder: NONE
                    layout: CONSTRAINED
                  )
                }
              }
            }
            Wallpaper {
              data {
                Name
                Hot
                New
                Bestseller
                Sale
                Modern
                Image {
                  localFiles {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED, width: 400)
                    }
                  }
                }
              }
            }
          }
        }
        Pattern {
          data {
            nameMn: mn
            nameEn: en
            textMn: Mongol_tailbar
            textEn: English_tailbar
            Image {
              localFiles {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 800, quality: 100)
                }
              }
            }
            Icon {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    width: 100
                    placeholder: NONE
                    layout: CONSTRAINED
                  )
                }
              }
            }
            Wallpaper {
              data {
                Name
                Hot
                New
                Bestseller
                Sale
                Modern
                Image {
                  localFiles {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED, width: 400)
                    }
                  }
                }
              }
            }
          }
        }
        Room {
          data {
            nameMn: mn
            nameEn: en
            textMn: Mongol_tailbar
            textEn: English_tailbar
            Image {
              localFiles {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 800, quality: 100)
                }
              }
            }
            Icon {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    width: 100
                    placeholder: NONE
                    layout: CONSTRAINED
                  )
                }
              }
            }
            Wallpaper {
              data {
                Name
                Hot
                New
                Bestseller
                Sale
                Modern
                Image {
                  localFiles {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED, width: 400)
                    }
                  }
                }
              }
            }
          }
        }
        tailbarEn: Nemelt_Tailbar_EN {
          internal {
            content
          }
        }
        tailbarMn: Nemelt_Tailbar_MN {
          internal {
            content
          }
        }
        Link
        Hot
        New
        Bestseller
        Sale
        Modern
        mainImage: Image {
          localFiles {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 800, quality: 100)
            }
          }
        }
        tuslahImages: Tuslah_zuragnuud {
          localFiles {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 800, quality: 100)
            }
          }
        }
        Color {
          data {
            mn
            en
            Hex_Color
            General_color
            Wallpaper {
              data {
                Name
                Hot
                New
                Bestseller
                Sale
                Modern
                Image {
                  localFiles {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED, width: 400)
                    }
                  }
                }
              }
            }
          }
        }
        Country {
          data {
            mn
            en
            Flag {
              localFiles {
                childImageSharp {
                  gatsbyImageData(width: 50, quality: 100, layout: CONSTRAINED)
                }
              }
            }
          }
        }
        Brand {
          data {
            mn
            en
            Logo {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    width: 100
                    placeholder: NONE
                    quality: 100
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
        Ontsloh_baraa_bolgoh
        Size {
          data {
            urgun: Urgun__cm_
            urt: Urt__m_
            talbai: _1_boodliin_talbai__m2_
          }
        }
      }
      id
    }
  }
`

export default SingleProductTemplate
