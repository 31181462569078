import React, { useEffect, useState } from "react"
import SingleProductSwiper from "./SingleProductSwiper"
import { NiitlegShinj } from "../graphql/WallpaperPageQuery"
import { GiRolledCloth } from "react-icons/gi"
import SwiperProducts from "./SwiperProducts"
import {
  NameText,
  YndsenShinjText,
  PatternText,
  RoomText,
  OntslogText,
  LuxuryText,
  ColorText,
  CountryText,
  BrandText,
  TailbarText,
  SizeText,
  WidthText,
  RollLengthText,
  SaleText,
  SurfaceText,
  TypeWallLongText,
  ColorWallLongText,
  DarkColorWallLongText,
  PatternLongText,
} from "../graphql/QueryById"

const SingleProductIntroduction = ({ data }) => {
  const {
    id,
    productType,
    productName,
    productSlug,
    angilal,
    ontslog,
    luxury,
    filteredLuxWalls,
    pattern,
    capitalizedPatternNames,
    finishPatWallsJoin,
    room,
    tailbar,
    link,
    hot,
    newProduct,
    best,
    modern,
    ontslohBaraa,
    sale,
    image,
    tuslahImages,
    color,
    filteredRelColorWalls,
    country,
    flag,
    brand,
    brandLogo,
    urgun,
    urt,
    talbai,
    allSlideImage,
    badges,
  } = data ?? undefined

  // console.log(id)
  const [zoom, setZoom] = useState(false)
  const [zoomImage, setZoomImage] = useState(null)

  // console.log(productType)
  const [likedWallpapers, setLikedWallpapers] = useState()
  useEffect(() => {
    if (localStorage.getItem("likedWallpapers") === null) {
      localStorage.setItem("likedWallpapers", "[]")
    }
  }, [])

  const saveToLocalStorage = () => {
    // if (localStorage.getItem("likedWallpapers") === null) {
    //   localStorage.setItem("likedWallpapers", "[]")
    // }

    const oldData = JSON.parse(localStorage.getItem("likedWallpapers"))
    oldData.push(id)
    // console.log(oldData)

    localStorage.setItem("likedWallpapers", JSON.stringify(oldData))
    // localStorage.setItem("likedWallpapers", ["hi there", "neg", "hoyour"])
  }

  return (
    <div>
      {/* ################## IMAGE SECTION ############## */}
      <div className="grid grid-cols-1 xl:space-x-2 lg:grid-cols-2 md:grid-cols-5">
        {/* Swiper section */}

        <div className="relative md:col-span-2 lg:col-span-1">
          <SingleProductSwiper
            images={allSlideImage}
            setZoomImage={setZoomImage}
            setZoom={setZoom}
            zoom={zoom}
          />
          <div className="">{badges}</div>
        </div>
        <div className="relative grid mb-10 lg:col-span-1 md:mx-0 justify-items-center md:col-span-3">
          {/* Zoom image */}
          <div
            className={`absolute top-0 object-cover h-full overflow-hidden z-10 bg-gray-50 md:mr-1 lg:-ml-4 lg:-mr-4 xl:-mr-40 -ml-8 xl:-ml-14 shadow-2xl select-none -mt-10 -mb-10 ${
              zoom ? "hidden md:flex" : "hidden"
            }`}
          >
            {zoomImage}
          </div>

          {/* Product information section */}
          <div className="max-w-lg mt-4 divide-y md:ml-2 md:mt-0">
            <div className="grid grid-cols-4 ">
              <div className="flex items-center justify-end px-2 text-right bg-gray-50">
                {NameText()}
              </div>
              <div className="grid items-center object-contain h-12 grid-cols-1 col-span-3 gap-2 py-1 pl-4 font-bold">
                {productName}
              </div>
            </div>
            <div className="grid grid-cols-4 ">
              <div className="flex items-center justify-end px-2 text-right bg-gray-50">
                {YndsenShinjText()}
              </div>
              <div className="relative grid items-center object-contain h-12 max-w-xs grid-cols-6 col-span-3 gap-1 px-4 py-1">
                {NiitlegShinj().map((item, index) => (
                  <div key={index} className="inline-flex group">
                    <div className="inline-flex w-8 h-8 transform group-hover:scale-125">
                      {item.icon}
                    </div>
                    <div className="absolute inset-x-0 z-10 items-center justify-center hidden p-2 leading-tight text-center text-white bg-gray-700 rounded shadow-md group-hover:flex group-focus:flex sm:mt-14 bottom-12 bg-opacity-90">
                      {item.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {ontslog && (
              <div className="grid grid-cols-4 ">
                <div className="flex items-center justify-end px-2 text-right bg-gray-50">
                  {OntslogText()}
                </div>
                <div className="relative grid items-center object-contain h-12 grid-cols-6 col-span-3 gap-2 py-1 pl-4">
                  {ontslog.map((item, index) => (
                    <div key={index} className="inline-flex group">
                      <div className="inline-flex transform group-hover:scale-125">
                        {item.icon}
                      </div>
                      <div className="absolute inset-x-0 z-10 items-center justify-center hidden p-2 leading-tight text-center text-white bg-gray-700 rounded shadow-md group-hover:flex group-focus:flex sm:mt-14 bottom-12 bg-opacity-90">
                        {item.name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {luxury && (
              <div className="grid grid-cols-4 ">
                <div className="flex items-center justify-end px-2 text-right bg-gray-50">
                  {LuxuryText()}
                  {/* Luxury */}
                </div>
                <div className="relative grid items-center object-contain h-12 grid-cols-6 col-span-3 gap-2 py-1 pl-4">
                  {luxury.map((item, index) => (
                    <div key={index} className="inline-flex group">
                      <div className="inline-flex transform group-hover:scale-125">
                        {item.icon}
                      </div>
                      <div className="absolute inset-x-0 z-10 items-center justify-center hidden p-2 leading-tight text-center text-white bg-gray-700 rounded shadow-md group-hover:flex group-focus:flex sm:mt-14 bottom-12 bg-opacity-90">
                        {item.name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {pattern && (
              <div className="grid grid-cols-4 ">
                <div className="flex items-center justify-end px-2 text-right bg-gray-50">
                  {PatternText()}
                </div>
                <div className="relative grid items-center object-contain h-12 grid-cols-6 col-span-3 gap-2 py-1 pl-4 ">
                  {pattern.map((item, index) => (
                    <div key={index} className="inline-flex group">
                      <div className="inline-flex transform group-hover:scale-125">
                        {item.icon}
                      </div>
                      <div className="absolute inset-x-0 z-10 items-center justify-center hidden p-2 leading-tight text-center text-white bg-gray-700 rounded shadow-md group-hover:flex group-focus:flex sm:mt-14 bottom-12 bg-opacity-90">
                        {item.name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {room && (
              <div className="grid grid-cols-4 ">
                <div className="flex items-center justify-end px-2 text-right bg-gray-50">
                  {RoomText()}
                </div>
                <div className="relative grid items-center object-contain h-12 grid-cols-5 col-span-3 gap-2 py-1 pl-4">
                  {room.map((item, index) => (
                    <div key={index} className="inline-flex group">
                      <div className="inline-flex transform group-hover:scale-125">
                        {item.icon}
                      </div>
                      <div className="absolute inset-x-0 z-10 items-center justify-center hidden p-2 leading-tight text-center text-white bg-gray-700 rounded shadow-md group-hover:flex group-focus:flex sm:mt-14 bottom-12 bg-opacity-90">
                        {item.name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {color && (
              <div className="grid grid-cols-4 ">
                <div className="flex items-center justify-end px-2 text-right bg-gray-50">
                  {ColorText()}
                  {/* Өнгө */}
                </div>
                <div className="grid items-center object-contain h-12 grid-cols-4 col-span-3 gap-2 py-1 pl-4">
                  {/* {console.log(color)} */}
                  {color.map((item, index) => (
                    <div key={index} className="relative inline-flex group">
                      <div
                        className="inline-flex w-10 h-10 border rounded-full group-hover:border-2 group-hover:border-brand-darker"
                        style={{ backgroundColor: item.hex }}
                      ></div>
                      <div
                        className="absolute left-0 z-10 items-center hidden p-20 leading-tight text-gray-800 border rounded-full shadow-lg group-hover:flex group-focus:flex -top-36"
                        style={{ backgroundColor: item.hex }}
                      >
                        {item.colorName && (
                          <div
                            className={`absolute inset-0 z-20 items-center justify-center hidden leading-tight rounded-full group-hover:flex group-focus:flex ${
                              item.generalColor === "Бараандуу"
                                ? "text-white"
                                : "text-gray-800"
                            }`}
                          >
                            {item.colorName}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {country && (
              <div className="grid grid-cols-4 group">
                <div className="flex items-center justify-end px-2 text-right bg-gray-50">
                  {CountryText()}
                  {/* Улс */}
                </div>
                <div className="grid items-center object-contain h-12 grid-cols-6 col-span-3 gap-2 py-1 pl-4">
                  <div className="relative inline-flex ">
                    <div className="inline-flex transform shadow group-hover:scale-125 group-hover:hidden">
                      {flag}
                    </div>
                    <div className="hidden py-2 font-bold group-hover:flex">
                      {country}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {brand && (
              <div className="grid grid-cols-4 group">
                <div className="flex items-center justify-end px-2 text-right bg-gray-50">
                  {BrandText()}
                  {/* Брэнд */}
                </div>
                <div className="relative grid items-center object-contain h-12 grid-cols-2 col-span-3 gap-2 py-1 pl-4 ">
                  <div className="inline-flex ">
                    {brandLogo ? (
                      <div className="inline-flex shadow group-hover:hidden">
                        {brandLogo}
                      </div>
                    ) : (
                      <div className="inline-flex">{brand}</div>
                    )}
                    {brandLogo && (
                      <div className="hidden py-2 font-bold group-hover:flex">
                        {brand}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {urgun && (
              <div className="grid grid-cols-4 group">
                <div className="flex items-center justify-end px-2 text-right bg-gray-50">
                  <div className="hidden group-hover:flex">
                    <GiRolledCloth />
                  </div>
                  <div className="group-hover:hidden">{SizeText()}</div>
                </div>
                <div className="relative grid items-center object-contain h-12 grid-cols-2 col-span-3 gap-2 py-1 pl-4 ">
                  <div className="inline-flex space-x-2">
                    <span>{urgun}</span>
                    <span>{urt}</span>
                    <span>
                      {talbai}
                      <sup>2</sup>
                    </span>
                    <div className="absolute inset-x-0 z-10 items-center justify-center hidden p-2 leading-tight text-center text-white bg-gray-700 rounded shadow-md group-hover:block group-focus:flex sm:mt-14 bottom-12 bg-opacity-90">
                      {WidthText()}{" "}
                      <span className="font-semibold">{urgun}</span>,{" "}
                      {RollLengthText()}{" "}
                      <span className="font-semibold">{urt}</span>,{" "}
                      {SurfaceText()}{" "}
                      <span className="font-semibold">
                        {talbai}
                        <sup>2</sup>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {tailbar && (
              <div className="grid grid-cols-4 ">
                <div className="flex items-center justify-end px-2 text-right bg-gray-50">
                  {TailbarText()}
                  {/* Тайлбар */}
                </div>
                <div className="grid items-center object-contain col-span-3 gap-2 py-1 pl-4 min-h-12">
                  <div className="relative inline-flex text-sm group">
                    {tailbar}
                  </div>
                </div>
              </div>
            )}
            {sale && (
              <div className="grid grid-cols-4 ">
                <div className="flex items-center justify-end px-2 text-right bg-gray-50">
                  {SaleText()}
                </div>
                <div className="grid items-center object-contain col-span-3 gap-2 py-1 pl-4 min-h-12">
                  <div className="relative inline-flex group">{sale}</div>
                </div>
              </div>
            )}
            {/* {link && (
                <div className="grid grid-cols-4 ">
                  <div className="flex items-center justify-end px-2 text-right bg-gray-50">
                    Link
                  </div>
                  <div className="grid items-center object-contain h-12 col-span-3 gap-2 py-1 pl-4 ">
                    <div className="relative inline-flex text-sm group">
                      <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex "
                      >
                        Бүтээгдэхүүний веб хуудас
                      </a>
                    </div>
                  </div>
                </div>
              )} */}

            {/* ################# LIKE BUTTON ########### */}
            {/* <button type="button" onClick={() => saveToLocalStorage()}>
              <h2>Дуртай бараагаар сонгох</h2>
            </button> */}
          </div>
          {/* <div>This is tailbar</div> */}
        </div>
      </div>
      {/* <div className="flex items-center justify-center pt-6 pb-4">
          <WallpaperFooterLink />
        </div> */}
      {luxury && (
        <div className="py-4 ">
          <div className="pb-2 pl-8 pr-4 font-semibold text-center md:text-left">
            {/* {luxury[0].name} төрлийн бусад ханын цааснууд */}
            {LuxuryText()} {TypeWallLongText()}
            {/* Luxury төрлийн бусад ханын цааснууд */}
          </div>
          <div className="">
            <SwiperProducts data={filteredLuxWalls} zoom={zoom} link />
          </div>
          {/* {console.log(filteredLuxWalls)} */}
        </div>
      )}
      {/* {angilal && (
          <div className="py-4">
            <div className="pb-2 pl-8 pr-4 font-semibold text-center md:text-left">
              {angilal[0].name} төрлийн бусад ханын цааснууд
            </div>
            <SwiperProducts data={angilal[0].relWall} zoom={zoom} />
          </div>
        )} */}
      {color && (
        <div className="py-4 ">
          <div className="pb-2 pl-8 pr-4 font-semibold text-center md:text-left">
            {color[0].generalColor === "Цайвардуу"
              ? ColorWallLongText()
              : DarkColorWallLongText()}
            {/* өнгийн бусад ханын цааснууд */}
          </div>
          {/* Swiper React section */}
          <SwiperProducts data={filteredRelColorWalls} zoom={zoom} link />
          {/* {console.log(filteredRelColorWalls)} */}
        </div>
      )}
      {pattern && (
        <div className="py-4 ">
          <div className="pb-2 pl-8 pr-4 font-semibold text-center md:text-left">
            {capitalizedPatternNames} {PatternLongText()}
            {/* хээтэй бусад ханын */}
            {/* цааснууд */}
            {/* {pattern[1].name} хээтэй бусад ханын цааснууд */}
          </div>
          {/* Swiper React section */}
          <SwiperProducts data={finishPatWallsJoin} zoom={zoom} link />
          {/* {console.log(finishPatWallsJoin)} */}
        </div>
      )}
    </div>
  )
}

export default SingleProductIntroduction
